//
// Navs
// --------------------------------------------------


// Base class
// --------------------------------------------------

.nav {
  padding-right: 0; // Override default ul/ol
  padding-left: initial;
}


// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  > li {
    float: right;

    // Actual tabs (as links)
    > a {
      margin-left: auto;
      margin-right: -2px;
      border-radius: @border-radius-base @border-radius-base 0 0;
    }
  }
}


// Pills
// -------------------------
.nav-pills {
  > li {
    float: right;

    // Links rendered as pills
    > a {
      border-radius: @nav-pills-border-radius;
    }
    + li {
      margin-right: 2px;
      margin-left: auto;
    }
  }
}


// Stacked pills
.nav-stacked {
  > li {
    float: none;
    + li {
      margin-right: 0; // no need for this gap between nav items
      margin-left: auto;
    }
  }
}


// Nav variations
// --------------------------------------------------

// Justified nav links
// -------------------------

.nav-justified {

  > .dropdown .dropdown-menu {
    right: auto;
  }
}

// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
.nav-tabs-justified {

  > li > a {
    // Override margin from .nav-tabs
    margin-left: 0;
    margin-right: auto;
  }

  @media (min-width: @screen-sm-min) {
    > li > a {
      border-radius: @border-radius-base @border-radius-base 0 0;
    }
  }
}
