//
// RTL Dropdown menus
// --------------------------------------------------

// Dropdown arrow/caret
.caret {
  margin-right: 2px;
  margin-left: 0;
}

// The dropdown menu (ul)
.dropdown-menu {
  right: 0;
  left: auto;
  float: left;
  text-align: right; // Ensures proper alignment if parent has it changed (e.g., modal footer)

  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    left: 0;
    right: auto;
    float: right;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  left: auto; // Reset the default from `.dropdown-menu`
  right: 0;
}
// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  left: 0;
  right: auto;
}

// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.

@media (min-width: @grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu {
      .dropdown-menu-right();
    }
    // Necessary for overrides of the default right aligned menu.
    // Will remove come v4 in all likelihood.
    .dropdown-menu-left {
      .dropdown-menu-left();
    }
  }
}

