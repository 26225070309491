//
// RTL Button groups
// --------------------------------------------------

// Make the div behave like a button
.btn-group,
.btn-group-vertical {
  > .btn {
    float: right;
  }
}

// Prevent double borders when buttons are next to each other
.btn-group {
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    margin-right: -1px;
    margin-left: 0px;
  }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  margin-right: -5px; // Offset the first child's margin
  margin-left: 0px;

  .btn-group,
  .input-group {
    float: right;
  }
  > .btn,
  > .btn-group,
  > .input-group {
    margin-right: 5px;
    margin-left: 0px;
  }
}

// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group > .btn:first-child {
  margin-right: 0;
  &:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
    .border-left-radius(0);
  }
}
// Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu immediately after it
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
    border-top-left-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
  .border-right-radius(0);
}

// Custom edits for including btn-groups within btn-groups (useful for including dropdown buttons within a btn-group)
.btn-group > .btn-group {
  float: right;
}

.btn-group.btn-group-justified > .btn,
.btn-group.btn-group-justified > .btn-group {
  float: none;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group > .btn-group:first-child {
  > .btn:last-child,
  > .dropdown-toggle {
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
    .border-left-radius(0);
  }
}
.btn-group > .btn-group:last-child > .btn:first-child {
    border-top-left-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
  .border-right-radius(0);
}

// Reposition the caret
.btn .caret {
  margin-right: 0;
}

// Vertical button groups
// ----------------------

.btn-group-vertical {

  > .btn + .btn,
  > .btn + .btn-group,
  > .btn-group + .btn,
  > .btn-group + .btn-group {
    margin-top: -1px;
    margin-right: 0;
  }
}
