//
// Input groups
// --------------------------------------------------

// Base styles
// -------------------------
.input-group {
  .form-control {
    // IE9 fubars the placeholder attribute in text inputs and the arrows on
    // select elements in input groups. To fix it, we float the input. Details:
    // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
    float: right;
  }
}

// Reset rounded corners
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  .border-right-radius(@border-radius-base);
  .border-left-radius(0);
}
.input-group-addon:first-child {
  border-left: 0px;
  border-right: 1px solid;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  .border-left-radius(@border-radius-base);
  .border-right-radius(0);
}
.input-group-addon:last-child {
  border-left-width: 1px;
  border-left-style: solid;
  border-right: 0px;
}

// Button input groups
// -------------------------
.input-group-btn {

  // Negative margin for spacing, position for bringing hovered/focused/actived
  // element above the siblings.
  > .btn {
    + .btn {
      margin-right: -1px;
      margin-left: auto;
    }
  }

  // Negative margin to only have a 1px border between the two
  &:first-child {
    > .btn,
    > .btn-group {
      margin-left: -1px;
      margin-right: auto;
    }
  }
  &:last-child {
    > .btn,
    > .btn-group {
      margin-right: -1px;
      margin-left: auto;
    }
  }
}
