//
// Popovers
// --------------------------------------------------


.popover {
  left: auto;
  text-align: right;
}

.popover {
  &.top > .arrow {
    right: 50%;
    left: auto;
    margin-right: -@popover-arrow-outer-width;
    margin-left: auto;
    &:after {
      margin-right: -@popover-arrow-width;
      margin-left: auto;
    }
  }
  &.bottom > .arrow {
    right: 50%;
    left: auto;
    margin-right: -@popover-arrow-outer-width;
    margin-left: auto;
    &:after {
      margin-right: -@popover-arrow-width;
      margin-left: auto;
    }
  }

}
