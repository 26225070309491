//
// Tables
// --------------------------------------------------

//TODO
caption {
  text-align: right;
}

th {
  text-align: right;
}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  @media screen and (max-width: @screen-xs-max) {

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-right: 0;
            border-left: initial;
          }
          > th:last-child,
          > td:last-child {
            border-left: 0;
            border-right: initial;
          }
        }
      }

    }
  }
}
