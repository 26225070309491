// RTL Embeds responsive
//
// Credit: Nicolas Gallagher and SUIT CSS.

.embed-responsive {

  .embed-responsive-item,
  iframe,
  embed,
  object {
    right: 0;
    left: auto;
  }

}
