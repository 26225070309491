//
// Temporary RTL style to fix bugs rapidly. 
// They will move to some place more appropriate later.
// --------------------------------------------------

.pull-right {
  &.flip {
    float: left !important;
  }
}
.pull-left {
  &.flip {
    float: right !important;
  }
}
