// RTL Media objects
// --------------------------------------------------


// Media image alignment
// -------------------------

.media {
  > .pull-left {
    margin-right: 10px;
    &.flip {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  > .pull-right {
      margin-left: 10px;
    &.flip {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

.media-right,
.media > .pull-right {
  padding-right: 10px;
  padding-left: initial;
}

.media-left,
.media > .pull-left {
  padding-left: 10px;
  padding-right: initial;
}


// Media list variation
// -------------------------

// Undo default ul/ol styles
.media-list {
  padding-right: 0;
  padding-left: initial;
  list-style: none;
}

