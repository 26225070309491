//
// 1. Set direction to RTL
//

html {
  direction: rtl;
}

//
// Remove default margin.
//

body {
  direction: rtl;
}
