//
// List groups
// --------------------------------------------------


// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
  padding-right: 0; // reset padding because ul and ol
  padding-left: initial;
}

