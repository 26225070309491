//
// RTL Badges
// --------------------------------------------------


// Base class
.badge {

  .nav-pills > li > a > & {
    margin-left: 0px;
    margin-right: 3px;
  }

  .list-group-item > & {
    float: left;
  }
  .list-group-item > & + & {
    margin-left: 5px;
    margin-right: auto;
  }
}
