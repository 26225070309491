//
// RTL Pager pagination
// --------------------------------------------------


.pager {
  padding-right: 0;
  padding-left: initial;

  .next {
    > a,
    > span {
      float: left;
    }
  }

  .previous {
    > a,
    > span {
      float: right;
    }
  }

}
